@if (region !== undefined && hierarchy !== undefined) {
   <div
      class="regionContainer"
      [ngClass]="{
         isChild: isChild,
         isCollapsed: !unfoldRegion,
      }"
   >
      <div
         class="regionHeader"
         (click)="unfold()"
         [ngClass]="{
            collapsedHeader: !unfoldRegion,
            inactiveClass: regionIsDisabled,
         }"
      >
         <div class="regionTitle">
            <lim-ui-icon icon="earthAmericas" iconSize="small" />{{ region.regionName }}
         </div>
         @if (!regionIsDisabled) {
            <lim-ui-icon
               [icon]="unfoldRegion ? 'angleUp' : 'angleDown'"
               iconSize="small"
            />
         } @else {
            <lim-ui-icon icon="stars" iconSize="small" />
         }
      </div>

      <div class="regionBody" [ngClass]="{ collapsed: !unfoldRegion }">
         @for (node of hierarchy.getChildrenOf(region); track node) {
            <div class="child">
               @if (nodeIsLocation(node)) {
                  <nav-location
                     [locationID]="node.locationID"
                     (locationActive)="checkActivity()"
                  />
               }
               @if (nodeIsRegion(node)) {
                  <nav-region
                     [region]="node"
                     [hierarchy]="hierarchy"
                     [isChild]="true"
                     (regionActive)="checkActivity()"
                  />
               }
            </div>
         }
      </div>
   </div>
}
