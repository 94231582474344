<!-- Upgrade buttons for basic plans  -->
@if (lang() && showUpgradeButton) {
   <lim-ui-secondary-button icon="boltRegular" (click)="openUpgradePage()">
      {{ lang().Upgrade }}
   </lim-ui-secondary-button>
}

<!-- Desktop full-size banner -->
@if (lang() && showTrialingBanner) {
   <div class="desktop-banner desktop-large-banner">
      <lim-ui-icon class="title-icon" icon="clock" iconSize="small" />
      <trial-banner-message
         [daysLeft]="daysLeft"
         [hoursLeft]="hoursLeft"
         [minutesLeft]="minutesLeft"
         [timeLeftInMS]="timeLeftInMS"
         [tierText]="tierText"
         [trialTier]="trialTier"
      />
   </div>
}

<!-- Desktop small icon banner -->
@if (lang()) {
   <div
      class="lim-ui-hide-on-mobile desktop-smaller-banner desktop-banner"
      (click)="showMessage()"
   >
      <lim-ui-icon class="title-icon" icon="clock" iconSize="small" />
   </div>
}
