@if (assets.size > 0) {
   <div class="global-search-section">
      <lim-ui-panel [title]="lang()?.Asset ?? ''" [noSidePadding]="true">
         <div class="listTable lim-ui-responsive-table">
            <div
               class="listItem listItemHeader lim-ui-responsive-table-header scrollable-list-header"
            >
               <div class="col-md-4 mobileMediumDisappear">
                  <sort-column_refactor [options]="sortColumnInfo.name" />
               </div>
               <div class="col-md-8 mobileMediumDisappear column-header-after-badges">
                  <sort-column_refactor [options]="sortColumnInfo.location" />
               </div>
            </div>
            @for (asset of assetsInView; track asset) {
               <div
                  class="listItem lim-ui-responsive-table-row"
                  [ngClass]="{
                     tranactionEveryOther: $odd == false,
                     tranactionEveryOtherWhite: $odd == true,
                  }"
                  (click)="openAsset(asset)"
               >
                  <div
                     class="lim-ui-responsive-table-cell col-md-4 table-column-badge-and-name-column"
                  >
                     <div class="icon-col">
                        <lim-ui-icon icon="cube" iconSize="small" />
                        <a
                           class="cursor table-link green-color-link"
                           [limbleHtml]="asset.assetName"
                        ></a>
                     </div>

                     @if (
                        this.searchHints.get(asset.assetID)?.searchHint;
                        as searchHint
                     ) {
                        <div class="table-column-badge" [limbleHtml]="searchHint"></div>
                     }
                  </div>
                  <div class="col-md-8">
                     <span>
                        {{ locationsIndex[asset.locationID].locationName }}
                     </span>
                  </div>
               </div>
            }
         </div>
      </lim-ui-panel>
      <div class="text-center">
         @if (assets.size > itemsPerPage) {
            <lim-ui-pagination
               [maxSize]="5"
               [rotate]="true"
               [page]="page"
               [pageSize]="itemsPerPage"
               [collectionSize]="assets.size"
               (pageChange)="pageChange($event)"
            />
         }
      </div>
   </div>
}
