<lim-ui-modal [extraLarge]="true">
   <lim-ui-basic-modal-header [title]="lang().Insights" (closeModal)="close()" />
   <lim-ui-modal-body>
      <roi-panel />
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-primary-button (click)="close()">
         {{ lang().Close }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
