import type { PipeTransform } from "@angular/core";
import { inject, Pipe } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

@Pipe({
   name: "notificationTitle",
   standalone: true,
})
export class NotificationTitlePipe implements PipeTransform {
   protected readonly lang = inject(ManageLang).lang;

   public transform(notificationType: number): string {
      const lang = this.lang() ?? {};
      switch (notificationType) {
         case 1:
            return lang.GeneratedFromAComment;
         case 2:
            return lang.GeneratedFromATaskAssignment;
         case 3:
            return lang.GeneratedFromAManualNotification;
         case 4:
            return lang.GeneratedFromATaskBeingCompleted;
         case 5:
            return lang.GeneratedFromATaskTakingTooLongToBeCompleted;
         case 6:
            return lang.GeneratedFromTheWorkRequestPortal;
         case 7:
            return lang.GeneratedFromAPurchaseOrderAssignment;
         case 8:
            return lang.GeneratedFromAPurchaseOrderDisapproval;
         case 9:
            return lang.GeneratedFromABillAssignment;
         case 10:
            return lang.GeneratedFromABillDisapproval;
         case 11:
            return lang.GeneratedFromATaskBeingStarted;
         case 12:
            return lang.GeneratedFromATaskBeingOverdue;
         case 13:
         case 14:
            return lang.GeneratedFromACheckOutRequestDecision;
         case 15:
            return lang.GeneratedFromAToolCheckOutRequest;
         case 16:
            return lang.GeneratedFromUpsellPaywall;
         default:
            return "";
      }
   }
}
