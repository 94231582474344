@if (purchaseOrders.size > 0) {
   <div class="global-search-section">
      <lim-ui-panel [title]="lang().ManagePOs" [noSidePadding]="true" [table]="true">
         <div class="listTable lim-ui-responsive-table">
            <div
               class="listItem listItemHeader lim-ui-responsive-table-header scrollable-list-header"
            >
               @for (column of columns; track column) {
                  <sort-column
                     [ngClass]="column.columnWidthClass"
                     column="{{ column.column }}"
                     name="{{ column.columnName }}"
                     [bind]="sort"
                     (bindChange)="sortChange($event)"
                  />
               }
            </div>
            @for (purchaseOrder of posInView; track purchaseOrder) {
               <po-list-item
                  [searchHints]="purchaseOrderSearchHints"
                  [purchaseOrderID]="purchaseOrder.poID"
               />
            }
         </div>
      </lim-ui-panel>
      <div class="text-center">
         @if (purchaseOrders.size > itemsPerPage) {
            <lim-ui-pagination
               [maxSize]="5"
               [rotate]="true"
               [page]="page"
               [pageSize]="itemsPerPage"
               [collectionSize]="purchaseOrders.size"
               (pageChange)="pageChange($event)"
            />
         }
      </div>
   </div>
}
