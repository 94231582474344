@if (vendors.size > 0) {
   <div class="global-search-section">
      <lim-ui-panel [title]="lang().Vendors" [noSidePadding]="true" [table]="true">
         <div class="listTable">
            <div class="listItem listItemHeader">
               <div class="col-md-12">
                  <sort-column_refactor [options]="sortColumnInfo" />
               </div>
            </div>

            @for (vendor of vendorsInView; track vendor) {
               <div
                  class="listItem"
                  [ngClass]="{
                     tranactionEveryOther: $odd == false,
                     tranactionEveryOtherWhite: $odd == true,
                  }"
                  (click)="openVendor(vendor)"
               >
                  <div class="col-md-12 table-column-badge-and-name-column">
                     <div class="icon-col">
                        <lim-ui-icon icon="gears" iconSize="small" />
                        <a class="cursor table-link" [limbleHtml]="vendor.vendorName"></a>
                     </div>

                     @if (searchHints.get(vendor.vendorID); as searchHint) {
                        <div class="table-column-badge" [limbleHtml]="searchHint"></div>
                     }
                  </div>
               </div>
            }
         </div>
      </lim-ui-panel>

      <div class="text-center">
         @if (vendors.size > itemsPerPage) {
            <lim-ui-pagination
               [maxSize]="5"
               [rotate]="true"
               [page]="page"
               [pageSize]="itemsPerPage"
               [collectionSize]="vendors.size"
               (pageChange)="pageChange($event)"
            />
         }
      </div>
   </div>
}
