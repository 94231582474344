@if (viewModel(); as viewModel) {
   @if (showTasksChecked() && viewModel.tasks.length) {
      <div class="global-search-section">
         <lim-ui-panel [title]="lang().Tasks" [noSidePadding]="true">
            <tasks-data-viewer
               [columns]="viewModel.columns"
               [tasks]="viewModel.tasks"
               [totalTasks]="viewModel.total"
               [searchHints]="viewModel.searchHints"
               [tableInScrollablePage]="true"
               [isLoading]="loadingTasks()"
            />
         </lim-ui-panel>
      </div>
   }
}
