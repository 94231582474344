import { computed, inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import type { Observable } from "rxjs";
import { Subject } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import {
   LegacySubscriptionHandles,
   SubscriptionHandles,
} from "src/app/subscriptions/services/manageSubscription";

export type TrialBannerOptions = {
   endOfTrial: number;
   trialTier: string;
   trialType: string;
};

@Injectable({ providedIn: "root" })
export class ManageTrials {
   private readonly _trialBanner$: Subject<TrialBannerOptions> = new Subject();
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public get trialBanner$(): Observable<TrialBannerOptions> {
      return this._trialBanner$;
   }

   public async checkForCurrentTrial(): Promise<AxiosResponse<any>> {
      //***important****
      //this call to phpscripts is used for health metrics -> customer login health, if it is going to flannel or changed let the internal team know first
      return axios({
         method: "POST",
         url: "phpscripts/manageTrials.php",
         params: {
            action: "checkForCurrentTrial",
         },
      }).then((answer) => {
         this._trialBanner$.next(answer.data);
         return answer;
      });
   }

   public getTrialDisplayInfo(
      trialTier: SubscriptionHandles | LegacySubscriptionHandles | string,
   ): { robotImageSrc: string; tierText: string } {
      switch (trialTier) {
         case SubscriptionHandles.Basic:
            return {
               robotImageSrc: "assets/img/professional-robot.jpg",
               tierText: this.lang().Basic,
            };
         case SubscriptionHandles.Standard:
            return {
               robotImageSrc: "assets/img/professional-robot.jpg",
               tierText: this.lang().Standard,
            };
         case SubscriptionHandles.Premium:
            return {
               robotImageSrc: "assets/img/business-plus-robot.jpg",
               tierText: this.lang().PremiumPlus,
            };
         case SubscriptionHandles.Enterprise:
            return {
               robotImageSrc: "assets/img/enterprise-robot.jpg",
               tierText: this.lang().Enterprise,
            };

         // Deprecated Legacy Plans Below
         case LegacySubscriptionHandles.Professional:
            return {
               robotImageSrc: "assets/img/professional-robot.jpg",
               tierText: this.lang().Professional,
            };
         case LegacySubscriptionHandles.Business:
            return {
               robotImageSrc: "assets/img/business-plus-robot.jpg",
               tierText: this.lang().BusinessPlus,
            };
         case LegacySubscriptionHandles.Starter:
            return {
               robotImageSrc: "assets/img/business-plus-robot.jpg",
               tierText: this.lang().Starter,
            };

         default:
            throw new Error("manageTrials.getTrialDisplayInfo error");
      }
   }
}
