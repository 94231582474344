<div class="mobile-footer">
   <div class="mobile-footer-full">
      <div
         class="mobile-footer-icon"
         (click)="gotoCustomDashboard()"
         [ngClass]="{ active: active() === 'customDashboards' }"
      >
         <lim-ui-icon
            icon="gaugeHigh"
            iconSize="medium-large"
            [iconColor]="active() === 'customDashboards' ? 'primary' : 'medium-grey'"
            class="footer-icon"
         />
         <span>{{ lang()?.Dashboards }}</span>
      </div>
      <div
         class="mobile-footer-icon"
         data-log="mobile-initiateTasks"
         (click)="gotoOpenTasks()"
         [ngClass]="{ active: active() === 'mobileTasks' }"
      >
         <lim-ui-icon
            icon="barsProgress"
            iconSize="medium-large"
            [iconColor]="active() === 'mobileTasks' ? 'primary' : 'medium-grey'"
            class="footer-icon"
         />
         {{ lang()?.Tasks }}
      </div>

      <div
         class="mobile-footer-icon mobile-home-button"
         data-log="mobile-initiateHomeButton"
         (click)="goHome()"
         [ngClass]="{ active: active() === 'home' }"
      >
         <img class="footer-img" [src]="'assets/img/limble-gear.svg'" />
         <span class="mobile-footer-img-text">{{ lang()?.HomeButton }}</span>
      </div>

      <div
         class="mobile-footer-icon"
         (click)="startWorkOrder()"
         data-log="mobile-initiateAWorkOrderViaNavBar"
      >
         <lim-ui-icon
            icon="wpforms"
            iconSize="medium-large"
            iconColor="medium-grey"
            class="footer-icon"
         />
         <span [limbleHtml]="lang()?.startWOWithAbbreviation"></span>
      </div>
      <div
         class="mobile-footer-icon"
         data-log="mobile-initiateSearch"
         (click)="showGlobalSearch()"
      >
         <lim-ui-icon
            icon="magnifyingGlass"
            iconSize="medium-large"
            iconColor="medium-grey"
            class="footer-icon"
         />
         {{ lang()?.Search }}
      </div>
   </div>
</div>
