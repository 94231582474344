import { Lookup } from "src/app/shared/utils/lookup";

export type Location = {
   locationName: string;
   locationID: number;
   regionID: number;
};

export type Region = {
   regionID: number;
   parentRegionID: number;
   regionName: string;
};

const noRegion: Region = {
   regionID: 0,
   regionName: "No Region",
   parentRegionID: 0,
};

export class LocationHierarchy {
   private readonly regions: Lookup<"regionID", Region>;
   private readonly locations: Lookup<"locationID", Location>;

   public constructor(
      regions: Iterable<Region>,
      locations: Iterable<Location>,
      private readonly options: { removeEmptyRegions: boolean } = {
         removeEmptyRegions: false,
      },
   ) {
      this.regions = new Lookup("regionID", regions).orderBy("regionName");
      this.locations = new Lookup("locationID", locations).orderBy("locationName");
      if (this.options.removeEmptyRegions === true) {
         this.removeEmptyRegions(this.regions);
      }
   }

   public hasRegions(): boolean {
      return this.regions.size > 0;
   }

   public getTopLevel(): Array<Region> {
      const topLevelRegions = this.regions
         .filter((region) => region.parentRegionID === 0)
         .orderBy("regionName");
      if (this.locations.some((location) => location.regionID === 0)) {
         topLevelRegions.setValue(noRegion);
      }
      return [...topLevelRegions];
   }

   public getChildrenOf(targetRegion: Region): Array<Region | Location> {
      if (targetRegion.regionID === 0) {
         return [
            ...this.locations
               .filter((location) => location.regionID === 0)
               .orderBy("locationName"),
         ];
      }
      const childRegions = this.regions
         .filter((region) => region.parentRegionID === targetRegion.regionID)
         .orderBy("regionName");
      const childLocations = this.locations
         .filter((location) => location.regionID === targetRegion.regionID)
         .orderBy("locationName");
      return [...childRegions, ...childLocations];
   }

   public search(search: string): LocationHierarchy {
      if (search.length === 0) {
         return this;
      }
      const matchedLocations = this.locations.filter((location) => {
         return (
            location.locationName.toLowerCase().includes(search.toLowerCase()) ||
            location.locationID.toString().includes(search)
         );
      });
      const matchedRegions = this.regions.filter((region) => {
         return (
            region.regionName.toLowerCase().includes(search.toLowerCase()) ||
            region.regionID.toString().includes(search)
         );
      });
      // We want to show all descendants of any matched regions
      while (this.missingDescendants(matchedRegions) === true) {
         for (const region of matchedRegions) {
            this.getChildrenOf(region).forEach((child) => {
               if ("locationName" in child) {
                  matchedLocations.setValue(child);
               } else {
                  matchedRegions.setValue(child);
               }
            });
         }
      }
      // Include parents of matched locations for context
      for (const location of matchedLocations) {
         const parentRegion = this.regions.find(
            (region) => region.regionID === location.regionID,
         );
         if (parentRegion) {
            matchedRegions.setValue(parentRegion);
         }
      }
      // We want to show all ancestors of any matched regions
      while (this.missingAncestors(matchedRegions) === true) {
         for (const region of matchedRegions) {
            const parentRegion = this.regions.get(region.parentRegionID);
            if (parentRegion) {
               matchedRegions.setValue(parentRegion);
            }
         }
      }
      return new LocationHierarchy(matchedRegions, matchedLocations, this.options);
   }

   public allRegions(): Lookup<"regionID", Region> {
      return this.regions.clone();
   }

   public allLocations(): Lookup<"locationID", Location> {
      return this.locations.clone();
   }

   public getRegion(regionID: number): Region | undefined {
      return this.regions.get(regionID);
   }

   public getLocation(locationID: number): Location | undefined {
      return this.locations.get(locationID);
   }

   public nodeCount(): number {
      return this.regions.size + this.locations.size;
   }

   public getUnassignedLocations(): Array<Location> {
      return [
         ...this.locations
            .filter((location) => location.regionID === 0)
            .orderBy("locationName"),
      ];
   }

   private missingAncestors(matchedRegions: Lookup<"regionID", Region>): boolean {
      for (const region of matchedRegions) {
         if (region.parentRegionID !== 0 && !matchedRegions.has(region.parentRegionID)) {
            return true;
         }
      }
      return false;
   }

   private missingDescendants(matchedRegions: Lookup<"regionID", Region>): boolean {
      for (const region of matchedRegions) {
         if (
            this.regions.some(
               (child) =>
                  child.parentRegionID === region.regionID &&
                  !matchedRegions.has(child.regionID),
            ) ||
            this.locations.some(
               (location) =>
                  location.regionID === region.regionID &&
                  !matchedRegions.has(location.regionID),
            )
         ) {
            return true;
         }
      }
      return false;
   }

   private removeEmptyRegions(regions: Lookup<"regionID", Region>): void {
      while (this.emptyRegionsExist(regions) === true) {
         regions
            .filter((region) => this.getChildrenOf(region).length === 0)
            .forEach((regionID) => {
               regions.delete(regionID);
            });
      }
   }

   private emptyRegionsExist(regions: Lookup<"regionID", Region>): boolean {
      return regions.some((region) => this.getChildrenOf(region).length === 0);
   }
}
