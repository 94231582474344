@if (lang()) {
   <div
      class="limble-header-nav-buttons desktop-banner desktop-large-banner critical-desktop-banner"
   >
      <lim-ui-icon class="title-icon" icon="triangleExclamation" iconSize="small" />&nbsp;
      <a [routerLink]="['/subscription']">
         {{ lang().pastDueBanner }}
      </a>
      &nbsp;
      <a href="mailto:accounting@limblecmms.com">accounting&#64;limblecmms.com</a>
   </div>
}

<div
   class="limble-header-nav-buttons desktop-banner desktop-smaller-banner critical-desktop-banner"
   (click)="showPastDueBannerMessage()"
>
   <lim-ui-icon class="title-icon" icon="triangleExclamation" iconSize="small" />
</div>
