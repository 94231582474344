@if (viewModel$ | async; as viewModel) {
   @if (viewModel.templates; as templates) {
      <div class="global-search-section">
         <lim-ui-panel [title]="lang().PMTemplates" [noSidePadding]="true" [table]="true">
            <div class="listTable lim-ui-responsive-table">
               @if (sort$ | async; as sort) {
                  <div
                     class="listItem listItemHeader lim-ui-responsive-table-header scrollable-list-header"
                  >
                     <sort-column
                        class="col-md-2"
                        column="checklistName"
                        name="{{ lang().Name }}"
                        [bind]="sort"
                        (bindChange)="sortChange($event)"
                     />
                     <div class="col-md-2">
                        {{ lang().Asset }}
                     </div>
                     <div class="col-md-4">
                        <sort-column
                           column="recurrences"
                           name="{{ lang().Schedules }}"
                           [bind]="sort"
                           (bindChange)="sortChange($event)"
                        />
                     </div>
                     <sort-column
                        class="col-md-2"
                        column="assignment"
                        name="{{ lang().AssignedTo }}"
                        [bind]="sort"
                        (bindChange)="sortChange($event)"
                     />

                     <div class="col-md-2 listLastItem">
                        {{ lang().Options }}
                     </div>
                  </div>
               }

               @for (template of templates; track template) {
                  <div class="listItem">
                     <task-template-list-item
                        class="task-list-item"
                        [template]="template"
                        [showAssetName]="true"
                        [inAssetCard]="false"
                        [reachedScheduleLimit]="reachedScheduleLimit"
                     />
                  </div>
               }
            </div>
         </lim-ui-panel>
         @if (viewModel.total; as total) {
            <div class="text-center">
               <data-viewer-paginator
                  [areResultsPerPageEnabled]="false"
                  [totalItems]="total"
                  [page]="dataViewerState.page()"
                  [pageSize]="dataViewerState.pageSize()"
                  (pageChange)="onPageChange($event)"
                  (pageSizeChange)="onPageSizeChange($event)"
               />
            </div>
         }
      </div>
   }
}
