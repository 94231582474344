<!-- Full sized Desktop banner-->
@if (lang()) {
   <div
      class="lim-ui-hide-on-mobile desktop-banner desktop-large-banner critical-desktop-banner"
   >
      <lim-ui-icon class="title-icon" icon="triangleExclamation" iconSize="small" />

      &nbsp;{{ lang().Critical }}:&nbsp;
      @if (!isSuperUser) {
         <span>
            {{ lang().FailedEmailAlert }}
         </span>
      }
      @if (isSuperUser) {
         <span>
            {{ lang().FailedEmailAlertSuperUser }}
            <span class="link-text desktop-link" (click)="openSmtpSettingsModal()">{{
               lang().SmtpSettings
            }}</span>
         </span>
      }
   </div>
}

<!-- Smaller sized desktop banner -->
@if (lang()) {
   <div
      class="lim-ui-hide-on-mobile desktop-smaller-banner desktop-banner critical-desktop-banner"
      (click)="showMessage()"
   >
      <lim-ui-icon class="title-icon" icon="triangleExclamation" iconSize="small" />
   </div>
}

<!-- Mobile banner -->
@if (lang()) {
   <lim-ui-alert
      [title]="lang().Critical"
      alertType="danger"
      [dismissible]="true"
      class="lim-ui-show-on-mobile"
   >
      <span
         >{{ bannerMessage }}
         @if (isSuperUser) {
            <span class="link-text" (click)="openSmtpSettingsModal()">{{
               lang().SmtpSettings
            }}</span>
         }
      </span>
   </lim-ui-alert>
}
