@if (lang()) {
   <ul class="acc-menu" id="sidebar" (click)="hideSideMenu()">
      <li class="sidebar-header">
         <lim-ui-minimal-icon-button
            icon="xmarkLargeRegular"
            iconSize="medium-large"
            (click)="hideSideMenu(); $event.stopPropagation()"
         />
         @if (usingCustomLogo) {
            <img
               [src]="customLogoURL"
               class="mobile-custom-logo"
               [attr.crossorigin]="crossOriginAnonymous ? 'anonymous' : undefined"
            />
         }
      </li>
      <li
         [ngClass]="{ active: navActive[0] === 'login' }"
         [hidden]="!(currentUser === 'none')"
      >
         <a (click)="login()" class="menu-link">
            <span class="icon-container">
               <lim-ui-icon icon="rightToBracket" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().login }}</span>
            <!--Login-->
         </a>
      </li>

      <li
         class="allowOffline"
         [ngClass]="{ active: navActive[0] === 'mobileDashboard' }"
         [hidden]="!(currentUser !== 'none')"
      >
         <a
            [routerLink]="['/mobileDashboard']"
            data-log="mobile-initiateHome"
            class="menu-link"
         >
            <span class="icon-container">
               <lim-ui-icon icon="earthAmericas" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().Home }}</span>
            <!--Main Screen-->
         </a>
      </li>

      <li
         [ngClass]="{ active: navActive[0] === 'customDashboardMobile' }"
         [hidden]="!cred41"
      >
         <a
            [routerLink]="['/customDashboardMobile', currentCustomDashboard]"
            data-log="mobile-initiateDashboards"
            class="menu-link"
         >
            <span class="icon-container">
               <lim-ui-icon icon="gaugeHigh" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().Dashboards }}</span>
         </a>
      </li>

      <li
         [ngClass]="{ active: navActive[0] === 'mobileTasks' }"
         [hidden]="!(cred42 || cred35)"
      >
         <a
            [routerLink]="['/mobileTasks']"
            class="menu-link"
            [attr.data-log]="
               !((cred35 && !cred42) || (cred35 && cred42))
                  ? 'mobile-initiateOpenTasks'
                  : undefined
            "
         >
            <span class="icon-container">
               <lim-ui-icon icon="barsProgress" iconSize="medium" />
            </span>
            <span [hidden]="!(cred42 && !cred35)" class="link-title">{{
               lang().myTasks
            }}</span>
            <span
               [hidden]="!((cred35 && !cred42) || (cred35 && cred42))"
               class="link-title"
               >{{ lang().OpenTasks }}</span
            >
         </a>
      </li>

      <li [hidden]="!cred43" class="allowOffline">
         <a
            (click)="startWorkOrder()"
            data-log="mobile-initiateAWorkOrder"
            class="menu-link"
         >
            <span class="icon-container">
               <lim-ui-icon icon="wpforms" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().startAWorkOrder }}</span>
         </a>
      </li>

      <li [hidden]="!cred45">
         <a
            (click)="reportProblem()"
            data-log="mobile-initiateAWorkRequest"
            class="menu-link"
         >
            <span class="icon-container">
               <lim-ui-icon icon="triangleExclamation" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().reportAProblem }}</span>
         </a>
      </li>

      @if (currentUser !== "none") {
         <li>
            <a
               (click)="showGlobalSearch()"
               data-log="mobile-initiateAGlobalSearch"
               class="menu-link"
            >
               <span class="icon-container">
                  <lim-ui-icon icon="magnifyingGlass" iconSize="medium" />
               </span>
               <span class="link-title">{{ lang().GlobalSearch }}</span>
            </a>
         </li>
      }

      <li
         [ngClass]="{ active: navActive[0] === 'mobileAssets' }"
         [hidden]="!(cred110 && currentUser !== 'none')"
      >
         <a (click)="lookUpAsset()" class="menu-link">
            <span class="icon-container">
               <lim-ui-icon icon="cubes" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().SearchAsset }}</span>
         </a>
      </li>

      <li
         [ngClass]="{ active: navActive[0] === 'mobilePart' }"
         [hidden]="!showLookupPartsOption"
         [limUiPopover]="startPartsPopover"
         [limUiPopoverType]="'upsell'"
         [limUiPopoverPlacement]="'bottom'"
         [limUiPopoverHidden]="featureUnlimitedParts || featureLimitedNumber"
      >
         <a (click)="lookUpPart()" class="menu-link">
            <span class="icon-container">
               <lim-ui-icon icon="gears" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().SearchPart }}</span>
            @if (!featureUnlimitedParts && !featureLimitedNumber) {
               <lim-ui-badge icon="stars" />
            }
            <ng-template #startPartsPopover>
               <upsell-popover
                  [message]="lang().FeatureUnlimitedPartsPopUpMessage"
                  [pricingLinkText]="lang().LearnMore"
               />
            </ng-template>
         </a>
      </li>

      <li
         [ngClass]="{ active: navActive[0] === 'mobileVendor' }"
         [hidden]="!showLookupVenderOption"
         [limUiPopover]="startVendorsPopover"
         [limUiPopoverType]="'upsell'"
         [limUiPopoverPlacement]="'bottom'"
         [limUiPopoverHidden]="featureUnlimitedVendors || featureLimitedNumber"
      >
         <a (click)="lookUpVendor()" class="menu-link">
            <span class="icon-container">
               <lim-ui-icon icon="addressCard" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().SearchVendor }}</span>
            @if (!featureUnlimitedVendors && !featureLimitedNumber) {
               <lim-ui-badge icon="stars" />
            }
            <ng-template #startVendorsPopover>
               <upsell-popover
                  [message]="lang().FeatureUnlimitedVendorsMessage"
                  [pricingLinkText]="lang().LearnMore"
               />
            </ng-template>
         </a>
      </li>

      @if (showLookupPOsOption) {
         <li
            [limUiPopover]="startLookUpPOPopover"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureUnlimitedPOs || featureLimitedNumber"
         >
            <a (click)="lookupPO()" class="menu-link">
               <span class="icon-container">
                  <lim-ui-icon icon="filePowerpoint" iconSize="medium" />
               </span>
               <span class="link-title">{{ lang().SearchPO }}</span>
               @if (!featureUnlimitedPOs && !featureLimitedNumber) {
                  <lim-ui-badge icon="stars" />
               }
               <ng-template #startLookUpPOPopover>
                  <upsell-popover
                     [message]="lang().FeatureUnlimitedPOsMessage"
                     [pricingLinkText]="lang().LearnMore"
                  />
               </ng-template>
            </a>
         </li>
      }

      @if (showReceivePOItemsOption) {
         <li
            [limUiPopover]="startReceivePOPopover"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureUnlimitedPOs || featureLimitedNumber"
         >
            <a (click)="receivePOItems()" class="menu-link">
               <span class="icon-container">
                  <lim-ui-icon icon="truck" iconSize="medium" />
               </span>
               <span class="link-title">{{ lang().ReceivePOItems }}</span>
               @if (!featureUnlimitedPOs && !featureLimitedNumber) {
                  <lim-ui-badge icon="stars" />
               }
               <ng-template #startReceivePOPopover>
                  <upsell-popover
                     [message]="lang().FeatureUnlimitedPOsMessage"
                     [pricingLinkText]="lang().LearnMore"
                  />
               </ng-template>
            </a>
         </li>
      }

      @if (showQRCode && currentUser !== "none") {
         <qr-code-button type="list" (scan)="scanQrCallback($event)" />
      }

      <li
         [ngClass]="{ active: navActive[0] === 'mapManagement' }"
         [hidden]="featureMaps == 0 || !cred198"
      >
         <a [routerLink]="['/mapManagement', currentMap]" class="menu-link">
            <span class="icon-container">
               <lim-ui-icon icon="map" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().Maps }}</span>
         </a>
      </li>

      <li [hidden]="!(currentUser !== 'none') || !cred60">
         <a (click)="addAsset()" class="menu-link">
            <span class="icon-container">
               <lim-ui-icon icon="cube" iconSize="medium" />
            </span>
            <span class="link-title">{{ lang().addAnAsset }}</span>
         </a>
      </li>
   </ul>
}

@if (lang()) {
   <span>
      @if (poweredBy || usingCustomLogo) {
         <div class="powered-by mobile-powered-by">
            <a href="https://limblecmms.com" target="_blank">
               <span>
                  {{ lang().PoweredBy }}
               </span>
               <img
                  src="assets/img/logo-light-background.svg"
                  class="logoImgHome"
                  alt="Limble CMMS"
               />
            </a>
         </div>
      }
   </span>
}
