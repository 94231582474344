<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      title="{{ welcomeMessage ? lang().WelcomeToLimbleCMMS : lang().changePassword }}"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      @if (welcomeMessage || PasswordExpiredMsgDayWarning?.length) {
         <lim-ui-info-panel>
            @if (welcomeMessage) {
               <label class="control-label">
                  {{ lang().ForYourConveniencePleaseUpdateYourPassword }}
               </label>
            }
            @if (PasswordExpiredMsgDayWarning?.length) {
               <lim-ui-alert alertType="danger">
                  {{ PasswordExpiredMsgDayWarning }} <b>{{ date }}</b>
               </lim-ui-alert>
            }
         </lim-ui-info-panel>
      }
      <lim-ui-panel>
         <div class="config-container">
            <div class="form-group" [ngClass]="{ 'has-error': oldPasswordError }">
               <label class="control-label">{{ lang().OldPassword }}</label>
               <div class="input-controller">
                  <input
                     type="password"
                     class="form-control"
                     [(ngModel)]="_oldPassword"
                     placeholder="{{ lang().OldPassword }}"
                     required
                  />
               </div>
            </div>
         </div>
      </lim-ui-panel>
      <lim-ui-panel>
         <div class="config-container">
            <div class="form-group" [ngClass]="{ 'has-error': newPassword1Error }">
               <label class="control-label">{{ lang().NewPassword }}</label>
               <div class="input-controller">
                  <input
                     type="password"
                     class="form-control"
                     [(ngModel)]="_newPassword1"
                     placeholder="{{ lang().NewPassword }}"
                  />
               </div>
            </div>

            <div class="form-group" [ngClass]="{ 'has-error': newPassword2Error }">
               <label class="control-label">{{ lang().RepeatPassword }}</label>
               <div class="input-controller">
                  <input
                     type="password"
                     class="form-control"
                     [(ngModel)]="_newPassword2"
                     placeholder="{{ lang().RepeatPassword }}"
                  />
               </div>
            </div>
         </div>
      </lim-ui-panel>

      @if (loader) {
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      }

      @if (arrayErrorMsg?.length) {
         <lim-ui-alert [title]="lang().Warning" alertType="danger">
            @for (errMsg of arrayErrorMsg; track errMsg) {
               <div [limbleHtml]="errMsg"></div>
            }
         </lim-ui-alert>
      }
      @if (successMsg?.length) {
         <lim-ui-alert [title]="lang().Success" alertType="success">
            <div [limbleHtml]="successMsg"></div>
         </lim-ui-alert>
      }
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Update }}"
      (successClick)="submit(_oldPassword, _newPassword1, _newPassword2)"
   />
</lim-ui-modal>
