@if (lang()) {
   <span class="message-container">
      <span class="countdown-container">
         @if (timeLeftInMS > 0) {
            <span class="trial-text">
               {{ lang().Your }} {{ tierText }} {{ lang().TrialIsEndingIn }}
            </span>
         }
         @if (daysLeft > 0) {
            <span class="time-unit-container">
               <div class="time-value">{{ daysLeft }}</div>
               <div class="time-label">{{ lang().Days }}</div>
            </span>
         }
         @if (!daysLeft && hoursLeft > 0) {
            <span class="time-unit-container">
               <div class="time-value">{{ hoursLeft }}</div>
               <div class="time-label">{{ lang().Hours }}</div>
            </span>
         }
         @if (!hoursLeft && timeLeftInMS > 0) {
            <span class="time-unit-container">
               @if (minutesLeft < 1) {
                  <div class="time-label">{{ lang().LessThan }} 1 &nbsp;</div>
               }
               @if (minutesLeft >= 1) {
                  <div class="time-value">{{ minutesLeft }}</div>
               }
               @if (minutesLeft > 1) {
                  <div class="time-label">{{ lang().Minutes }}</div>
               }
               @if (minutesLeft <= 1) {
                  <div class="time-label">{{ lang().Minute }}</div>
               }
            </span>
         }
      </span>
      <span class="trial-text" data-log="trialBanner-learnMore">
         <a [routerLink]="['/subscription']">
            &nbsp;<span class="link-text">{{ lang().LearnMore }}</span
            >&nbsp;
         </a>
      </span>
   </span>
}
