import { Injectable } from "@angular/core";
import type { LimbleMap } from "src/app/shared/utils/limbleMap";
import type {
   SearchHintMatchOptions,
   SearchHints,
} from "src/app/tasks/search-hint/default-search-hint.service";
import { DefaultSearchHintService } from "src/app/tasks/search-hint/default-search-hint.service";

@Injectable({
   providedIn: "root",
})
export class TaskSearchHintService extends DefaultSearchHintService {
   private readonly TASK_SEARCH_LOOKUP_ORDER: Array<keyof SearchHints> = [
      "taskID",
      "taskName",
      "assetName",
      "completers",
      "userAssignment",
      "profileAssignment",
      "requestFields",
      "locationName",
      "dates",
      "taskDescription",
      "partName",
   ];

   public constructor() {
      super();
   }

   public override getSearchHints(
      entityPrimaryKey: string | number,
      entities: { hint?: SearchHints }[],
      searchValue: string | undefined,
      options?: SearchHintMatchOptions,
   ): LimbleMap<number, string> {
      // TODO - this needs to be supported in JIT tasks search.
      // task associations - checklistName, checklistComments, requestDescription, checklistEmailCN, requestName, requestPhone
      // task priority Name
      // task custom tags
      // task estimated time
      // task checklistPromptTimeTotal (total time spent)
      // invoice on tasks
      // notes on tasks
      // associated season names (season/holiday names)

      const taskSearchOptions = options ?? {
         boldMatch: true,
         matchLookupOrder: this.TASK_SEARCH_LOOKUP_ORDER,
      };

      return super.getSearchHints(
         entityPrimaryKey,
         entities,
         searchValue,
         taskSearchOptions,
      );
   }
}
