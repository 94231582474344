import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input } from "@angular/core";
import {
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   PaginationComponent,
   PanelComponent,
} from "lim-ui";
import { ReplaySubject, Subject, takeUntil } from "rxjs";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetSortFilterColumnInfo } from "src/app/assets/types/field/asset-field-sort-column.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { SortColumn_refactor } from "src/app/shared/components/global/sortColumnModal/sortColumn_refactor.element.component";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import type { NotUndefined } from "src/app/shared/types/utility-types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { DEFAULT, REVERSE, type Comparator } from "src/app/shared/utils/sortingHelpers";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "global-search-asset-list",
   templateUrl: "./global-search-asset-list.component.html",
   styleUrls: ["./global-search-asset-list.component.scss"],
   standalone: true,
   imports: [
      PanelComponent,
      SortColumn_refactor,
      NgClass,
      IconComponent,
      LimbleHtmlDirective,
      PaginationComponent,
   ],
})
export class GlobalSearchAssetListComponent implements OnDestroy, OnInit {
   @Input() search?: string;
   @Input() assetIDs?: Array<number>;

   public readonly sortChanges: ReplaySubject<AssetSortFilterColumnInfo> =
      new ReplaySubject();
   public assets: Lookup<"assetID", Asset> = new Lookup("assetID");
   public sort: string = "assetName";
   public page: number = 1;
   public itemsPerPage!: number;
   public assetsInView: Lookup<"assetID", Asset> = new Lookup("assetID");
   public locationsIndex!: Record<number, any>;
   public sortColumnInfo!: {
      name: AssetSortFilterColumnInfo;
      location: AssetSortFilterColumnInfo;
   };
   public searchHints: Map<number, { searchHint: string; searchFound: boolean }> =
      new Map();

   private readonly destroy$ = new Subject<void>();

   private readonly betterDate = inject(BetterDate);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLocation = inject(ManageLocation);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = this.manageLang.lang;

   public ngOnInit(): void {
      this.itemsPerPage =
         this.manageUser.getCurrentUser()?.userInfo.userUIPreferences.itemsPerPage ?? 10;
      this.locationsIndex = this.manageLocation.getLocationsIndex();
      this.sortColumnInfo = {
         name: {
            columnName: this.lang()?.Name ?? "",
            sortProperty: "assetName",
            locationOfProperty: "asset",
            sortDirection: "ascending",
            entityUniqueID: 0,
            sortChanges: this.sortChanges,
         },
         location: {
            columnName: this.lang()?.Location ?? "",
            sortProperty: "locationName",
            locationOfProperty: "asset",
            sortDirection: "ascending",
            entityUniqueID: 0,
            sortChanges: this.sortChanges,
         },
      };
      this.sortChanges.pipe(takeUntil(this.destroy$)).subscribe({
         next: (sortOptions) => {
            this.orderAssets(sortOptions);
         },
      });
      if (this.search === undefined) {
         throw new Error("required input `search` is not defined");
      }
      if (this.assetIDs === undefined) {
         throw new Error("required input `assetIDs` is not defined");
      }
      this.setAssets();
   }

   public ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   protected openAsset(asset): void {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   private setAssets(): void {
      assert(this.assetIDs !== undefined);
      this.assets = new Lookup(
         "assetID",
         this.assetIDs
            .map((assetID) => this.manageAsset.getAsset(assetID))
            .filter((asset): asset is NotUndefined<typeof asset> => asset !== undefined),
      );
      this.setAssetsInView();
   }

   private setAssetsInView() {
      this.assetsInView = this.assets.slice(
         (this.page - 1) * this.itemsPerPage,
         this.page * this.itemsPerPage,
      );
      this.addSearchHintsForAssets();
   }

   protected addSearchHintsForAssets() {
      assert(this.search !== undefined);
      this.manageFilters.filterAssetsToNameAndTextFields_refactor(
         this.assetsInView,
         this.manageAsset.getFields(),
         this.manageAsset.getFieldValues(),
         this.manageAsset.getFieldValueFiles(),
         this.searchHints,
         {
            search: this.search,
            hier: false,
            field: false,
            includeChildren: true,
         },
         this.manageAsset,
         this.betterDate,
      );
   }

   protected pageChange(newPage: number): void {
      this.page = newPage;
      this.setAssetsInView();
   }

   private orderAssets(options: AssetSortFilterColumnInfo) {
      if (!options) {
         return;
      }
      const comparator = options.sortDirection === "ascending" ? DEFAULT : REVERSE;
      const sortKey = options.sortProperty;
      if (sortKey === "locationName") {
         this.sortByAssetLocationName(comparator);
      } else {
         this.assets = this.assets.orderBy(sortKey, comparator);
      }
      this.setAssetsInView();
   }

   private sortByAssetLocationName(comparator: Comparator): void {
      const locationsIndex = this.manageLocation.getLocationsIndex();
      const sortByLocationName = (partA: Asset, partB: Asset) => {
         const locationA = locationsIndex[partA.locationID].locationName;
         const locationB = locationsIndex[partB.locationID].locationName;
         return comparator(locationA, locationB);
      };
      this.assets = this.assets.sort(sortByLocationName);
   }
}
