<header id="mobileHeaderRef">
   <div
      class="mobile-app-header"
      [ngClass]="{
         'limble-green-header': mobilePageTitleColor === 'green',
      }"
   >
      <div class="mobile-alert-banner-area">
         @if (smtpSettings.emailsFailing && smtpSettings.enabled) {
            <failed-email-banner />
         }
      </div>

      <div class="mobile-header-flex-container">
         <div class="mobile-header-left">
            <span
               class="hamburger-menu-wrapper"
               (click)="toggleShowMobileSideMenu()"
               data-log="mobile-initiateSideMenu"
            >
               <lim-ui-icon
                  icon="barsRegular"
                  [iconColor]="mobilePageTitleColor === 'green' ? 'white' : 'standard'"
                  class="mobile-header-icon"
               />
            </span>

            <div>
               {{ title() }}
            </div>
         </div>
         <div class="mobile-header-right">
            <span class="mobile-notifications icon-wrapper">
               <notifications-popover [currentUser]="currentUser" position="bottom-end" />
            </span>

            <div
               limUiTooltip="{{ currentUser?.userInfo?.fName }} {{
                  currentUser?.userInfo?.lName
               }}"
               [limUiPopover]="accountManagementPopover"
               [limUiPopoverPlacement]="'bottom-end'"
            >
               @if (currentUser) {
                  <user-image [user]="currentUser" />
               }

               <ng-template #accountManagementPopover>
                  <account-management-popover
                     [currentUser]="currentUser"
                     [showFeatureRequestBoard]="showFeatureRequestBoard"
                     position="top left"
                  />
               </ng-template>
            </div>
         </div>
      </div>
   </div>
</header>
