<div class="connection-lost-overlay">
   <div class="connection-lost-modal">
      <div class="title">
         <lim-ui-icon icon="wifiSlash" iconSize="medium" />
         {{ lang()?.ConnectionLost }}
      </div>
      <div class="content">
         <div class="content-title">{{ lang()?.NoConnectionFound }}</div>
         <div class="lim-ui-fonts-body">
            {{ lang()?.OperateOfflineMode }}
         </div>
         <div class="capabilities">
            <div class="capability">
               <lim-ui-icon icon="circleCheck" iconSize="small" iconColor="success" />
               <div class="lim-ui-fonts-body">
                  {{ lang()?.WorkOnOpen }} + {{ lang()?.InProgressTasks }}
               </div>
            </div>
            <div class="capability">
               <lim-ui-icon icon="circleCheck" iconSize="small" iconColor="success" />
               <div class="lim-ui-fonts-body">
                  {{ lang()?.CompleteOpen }} + {{ lang()?.InProgressTasks }}
               </div>
            </div>
            <div class="capability">
               <lim-ui-icon icon="circleXmark" iconSize="small" iconColor="danger" />
               <div class="lim-ui-fonts-body">{{ lang()?.CreateTasks }}</div>
            </div>
         </div>
         <div class="lim-ui-fonts-body">
            {{ lang()?.WorkSyncsToAccountMsg }}
         </div>
         @if (isLimited()) {
            <div class="data-limit-warning">
               {{ lang()?.LiteDataLimitReachedWarning }}
            </div>
         }
         @if (isLiteEnabled() !== LiteEnabledState.Enabled || isLitePrepared()) {
            <lim-ui-primary-button
               (click)="goOffline()"
               [disabled]="isLiteEnabled() !== LiteEnabledState.Enabled"
            >
               @if (isLiteEnabled() === LiteEnabledState.Unavailable) {
                  <lim-ui-icon icon="stars" iconSize="small" />
               }
               <span>{{ lang()?.ContinueWorkingOffline }}</span>
               <lim-ui-icon icon="arrowRight" iconSize="small" class="end-icon" />
            </lim-ui-primary-button>
            @if (isLiteEnabled() === LiteEnabledState.Unavailable) {
               <div class="fine-print">
                  {{ lang()?.OfflineAvailableOnPremiumMsg }}
               </div>
            }
            @if (isLiteEnabled() === LiteEnabledState.Disabled) {
               <div class="fine-print">
                  {{ lang()?.PleaseEnableOfflineMode }}
               </div>
            }
         }

         @if (isLiteEnabled() === LiteEnabledState.Enabled && !isLitePrepared()) {
            <lim-ui-secondary-button [disabled]="true">
               {{ lang()?.TaskSyncIncomplete }}
            </lim-ui-secondary-button>
            <div class="fine-print">*{{ lang()?.ReconnectToCacheTasksMsg }}</div>
         }
      </div>
   </div>
</div>
