import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   PaginationComponent,
   PanelComponent,
} from "lim-ui";
import type { Subscription } from "rxjs";
import { ReplaySubject } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { SortColumn_refactor } from "src/app/shared/components/global/sortColumnModal/sortColumn_refactor.element.component";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { PartSortFilterColumnInfo } from "src/app/shared/types/general.types";
import type { NotUndefined } from "src/app/shared/types/utility-types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import type { Comparator } from "src/app/shared/utils/sortingHelpers";
import { DEFAULT, REVERSE } from "src/app/shared/utils/sortingHelpers";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "global-search-part-list",
   templateUrl: "./global-search-part-list.component.html",
   styleUrls: ["./global-search-part-list.component.scss"],
   standalone: true,
   imports: [
      PanelComponent,
      SortColumn_refactor,
      NgClass,
      IconComponent,
      LimbleHtmlDirective,
      PaginationComponent,
   ],
})
export class GlobalSearchPartListComponent implements OnInit, OnDestroy {
   @Input() search?: string;
   @Input() partIDs?: Array<number>;

   public parts: Lookup<"partID", Part> = new Lookup("partID");
   public partsInView: Lookup<"partID", Part> = new Lookup("partID");
   public page: number = 1;
   public itemsPerPage: number;
   public sortChanges: ReplaySubject<PartSortFilterColumnInfo> = new ReplaySubject();
   private readonly sortChangesSub: Subscription;
   protected searchHints: Map<number, string> = new Map();
   protected sortColumnInfo: {
      name: PartSortFilterColumnInfo;
      location: PartSortFilterColumnInfo;
   };
   protected locationsIndex;

   private readonly manageObservables = inject(ManageObservables);
   private readonly manageParts = inject(ManageParts);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly betterDate = inject(BetterDate);
   private readonly manageLocation = inject(ManageLocation);
   private readonly managePo = inject(ManagePO);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.itemsPerPage =
         this.manageUser.getCurrentUser()?.userInfo.userUIPreferences.itemsPerPage ?? 10;
      this.sortChangesSub = this.sortChanges.subscribe((sortOptions) => {
         this.orderParts(sortOptions);
      });
      this.locationsIndex = this.manageLocation.getLocationsIndex();
      this.sortColumnInfo = {
         name: {
            columnName: this.lang().Name,
            sortProperty: "partName",
            locationOfProperty: "part",
            sortDirection: "ascending",
            entityUniqueID: 0,
            sortChanges: this.sortChanges,
         },
         location: {
            columnName: this.lang().Location,
            sortProperty: "locationName",
            locationOfProperty: "part",
            sortDirection: "ascending",
            entityUniqueID: 0,
            sortChanges: this.sortChanges,
         },
      };
   }

   public ngOnInit(): void {
      if (this.search === undefined) {
         throw new Error("required input `search` is not defined");
      }
      if (this.partIDs === undefined) {
         throw new Error("required input `partIDs` is not defined");
      }
      this.setParts();
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeManySubscriptions([this.sortChangesSub]);
   }

   protected openPart(part: Part): void {
      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: part.partID,
            locationID: part.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   private setParts(): void {
      assert(this.partIDs !== undefined);
      const partsArray = this.partIDs
         .map((partID) => this.manageParts.getPart(partID))
         .filter((part): part is NotUndefined<typeof part> => part !== undefined);
      this.parts = new Lookup("partID", partsArray);
      this.setPartsInView();
   }

   protected addSearchHintsForParts(): void {
      this.manageFilters.filterPartsToNameAndTextFields(
         this.partsInView,
         this.manageParts.getFields(),
         this.manageParts.getFieldValues(),
         this.manageParts.getFieldValueFiles(),
         this.searchHints,
         {
            search: this.search,
            hier: true,
            field: false,
         },
         this.betterDate,
         this.manageParts,
         this.managePo,
         this.manageAssociations,
      );
   }

   private orderParts(sortInfo: PartSortFilterColumnInfo): void {
      if (!sortInfo) {
         return;
      }
      const comparator = sortInfo.sortDirection === "ascending" ? DEFAULT : REVERSE;
      const sortKey = sortInfo.sortProperty;
      if (sortKey === "locationName") {
         this.sortByPartLocationName(comparator);
      } else {
         this.parts = this.parts.orderBy(sortKey, comparator);
      }
      this.setPartsInView();
   }

   private sortByPartLocationName(comparator: Comparator): void {
      const locationsIndex = this.manageLocation.getLocationsIndex();
      const sortByLocationName = (partA: Part, partB: Part) => {
         const locationA = locationsIndex[partA.locationID].locationName;
         const locationB = locationsIndex[partB.locationID].locationName;
         return comparator(locationA, locationB);
      };
      this.parts = this.parts.sort(sortByLocationName);
   }

   private setPartsInView(): void {
      this.partsInView = this.parts.slice(
         (this.page - 1) * this.itemsPerPage,
         this.page * this.itemsPerPage,
      );
      this.addSearchHintsForParts();
   }

   protected pageChange(newPage: number): void {
      this.page = newPage;
      this.setPartsInView();
   }
}
