import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   manageDemo,
} from "lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "manual-reset-pw",
   templateUrl: "./manualResetPw.modal.component.html",
   styleUrls: ["./manualResetPw.modal.component.scss"],
   standalone: true,
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      AlertComponent,
      PanelComponent,
      NgClass,
      FormsModule,
      LoadingAnimationComponent,
      LimbleHtmlDirective,
      BasicModalFooterComponent,
   ],
})
export class ManualResetPw implements OnInit {
   public modalInstance;
   public PasswordExpiredMsgDayWarning;
   public date;
   public welcomeMessage;
   public successMsg;
   public _oldPassword;
   public _newPassword1;
   public _newPassword2;
   public loader;
   public customerPasswordLength;
   public oldPasswordError;
   public newPassword1Error;
   public newPassword2Error;
   public arrayErrorMsg;

   private readonly manageLogin = inject(ManageLogin);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const currentUser = this.manageUser.getCurrentUser();
      const currentTime = Math.round(new Date().getTime() / 1000);
      const plusSevenDays = currentTime + 604800;
      //check if password is within 1 week and throw to reset password

      const date = new Date(currentUser.userInfo.passwordExpires * 1000)
         .toISOString()
         .slice(0, 10);
      if (
         currentUser.userInfo.passwordExpires < plusSevenDays &&
         currentUser.userInfo.passwordExpires > currentTime &&
         currentUser.userInfo.passwordExpiry == 1
      ) {
         this.PasswordExpiredMsgDayWarning = this.lang().PasswordExpiredMsgDayWarning;
         this.date = date;
      }

      this.customerPasswordLength = currentUser.userInfo.passwordMinLength
         ? currentUser.userInfo.passwordMinLength
         : 8;

      if (this.manageUser.getCurrentUser().userInfo.logins < 2) {
         this.welcomeMessage = true;
      }

      this.oldPasswordError = this.newPassword1Error = this.newPassword2Error = false;
      this.loader = false;
      this.successMsg = "";
      this.arrayErrorMsg = [];
   }

   close = () => {
      this.modalInstance.close(0);
   };

   submit = (oldPassword, newPassword1, newPassword2) => {
      this.oldPasswordError = this.newPassword1Error = this.newPassword2Error = false;
      if (manageDemo.demo) {
         //overrides so password can't be reset on demo
         return;
      }

      this.arrayErrorMsg = []; // reset error message array
      this.successMsg = false;

      if (!oldPassword || !newPassword1 || !newPassword2) {
         if (!oldPassword) {
            this.arrayErrorMsg.push(
               this.lang().TheOldPasswordIsBlank ?? "The Old Password is blank",
            );
            this.oldPasswordError = true;
         }

         if (!newPassword1) {
            this.arrayErrorMsg.push(
               this.lang().TheNewPassword1IsBlank ?? "The New Password is blank",
            );
            this.newPassword1Error = true;
         }

         if (!newPassword2) {
            this.arrayErrorMsg.push(
               this.lang().TheNewPassword2IsBlank ?? "The Repeat New Password is blank.",
            );
            this.newPassword2Error = true;
         }

         return;
      }

      if (newPassword1 != newPassword2) {
         this.arrayErrorMsg.push(this.lang().YourNewPasswordsDoNotMatchPleaseReenterThem);
         this.newPassword1Error = this.newPassword2Error = true;
      }

      if (
         newPassword1?.length < this.customerPasswordLength ||
         newPassword2?.length < this.customerPasswordLength
      ) {
         this.arrayErrorMsg.push(
            `${this.lang().yourPasswordMustBeAtLeast} ${this.customerPasswordLength} ${this.lang().charactersLong}`,
         );
         this.newPassword1Error = this.newPassword2Error = true;
      }

      if (!/^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<~`>/?]*$/.test(newPassword1)) {
         this.arrayErrorMsg.push(
            this.lang().yourPasswordMustContainOnlyLettersNumbersAndSpecialCharacters,
         );
         this.newPassword1Error = true;
      }

      if (oldPassword == newPassword1) {
         this.arrayErrorMsg.push(this.lang().YourNewPasswordCannotMatchYourOldPassword);
         this.newPassword1Error = true;
      }

      if (this.arrayErrorMsg.length === 0) {
         this._oldPassword = "";
         this._newPassword1 = "";
         this._newPassword2 = "";
         this.loader = true;
         this.manageLogin
            .manualResetPassword(oldPassword, newPassword1, newPassword2)
            .then((res) => {
               this.loader = false;
               this.manageLogin.setLoginLocalStorage({ password: newPassword1 });
               if (res.data.success == true) {
                  this.PasswordExpiredMsgDayWarning = "";
                  this.date = "";
                  this.alertService.addAlert(
                     this.lang().YourPasswordHasBeenSuccessfullyReset,
                     "success",
                     1000,
                  );
                  this.modalInstance.close(0);
               } else {
                  this.arrayErrorMsg.push(res.data.arr);
               }
            });
      }
   };
}
