import { inject, Component, computed } from "@angular/core";
import { RouterLink } from "@angular/router";
import { IconComponent } from "lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";

@Component({
   selector: "past-due-banner",
   templateUrl: "./pastDueBanner.element.component.html",
   styleUrl: "../../../shared/components/global/banners/banner-styles.scss",
   standalone: true,
   imports: [IconComponent, RouterLink],
})
export class PastDueBannerElementComponent {
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected showPastDueBannerMessage() {
      this.alertService.addAlert(this.lang().pastDueBanner, "danger", Infinity);
   }
}
