<header role="banner" class="app-desktop-header" data-html2canvas-ignore>
   <div class="app-header-content">
      <div id="logoArea">
         @if (logoURL()) {
            <div
               [routerLink]="!isExternalUser ? ['/dashboard'] : null"
               [ngClass]="{ 'cursor-pointer': !isExternalUser }"
            >
               @if (!useCustomLogo) {
                  <img [src]="logoURL()" [ngStyle]="logoURLDarkCSS" alt="Limble CMMS" />
               }
               @if (useCustomLogo) {
                  <img
                     [src]="customLogoURL"
                     class="custom-logo"
                     [attr.crossorigin]="crossOriginAnonymous ? 'anonymous' : undefined"
                  />
               }
            </div>
         }
      </div>
      @if (currentUser !== undefined && !!currentUser && lang()) {
         <div>
            <div class="navbar-header-end">
               <!-- Past due banner -->
               @if (
                  showPastDueReminder &&
                  (currentUser.profileParents.includes(7) ||
                     currentUser.profileParents.includes(412) ||
                     currentUser.userInfo?.userInternal === 1)
               ) {
                  <past-due-banner />
               }

               <!-- Note that the failed-email-banner takes precedence over the trial-banner in
                  the case where both might otherwise be present.
                  This is a product decision, and may be revisited in the future. -->
               @if (smtpSettings.emailsFailing && smtpSettings.enabled) {
                  <failed-email-banner class="limble-header-nav-buttons" />
               } @else if (
                  (trialStatus && trialStatus.trialType === "paywallTrial") ||
                  (isBasicPlan$ | async)
               ) {
                  <trial-banner
                     class="limble-header-nav-buttons"
                     [currentTrial]="trialStatus"
                  />
               }

               @if (isSuperUser && releaseInsightsModalFlag()) {
                  <lim-ui-minimal-icon-button
                     (click)="showInsightsModal()"
                     [limUiTooltip]="lang()?.Insights"
                     class="limble-header-nav-buttons"
                     icon="chartMixedUpCircleDollar"
                     iconSize="medium"
                  />
               }

               <lim-ui-minimal-icon-button
                  (click)="showGlobalSearch()"
                  [hidden]="currentUser?.workOrderUser == 1"
                  [limUiTooltip]="lang()?.GlobalSearch"
                  class="limble-header-nav-buttons"
                  icon="magnifyingGlassRegular"
                  iconSize="medium"
               />

               @if (isCustomerActive && isManagerOrSuperUser) {
                  <lim-ui-minimal-icon-button
                     class="limble-header-nav-buttons"
                     [limUiTooltip]="lang()?.gettingStarted"
                     (click)="openGettingStartedModal()"
                     icon="rocketLaunchRegular"
                     iconSize="medium"
                     dataLogLabel="general-initiateGettingStarted"
                  />
               }

               <lim-ui-minimal-icon-button
                  class="limble-header-nav-buttons"
                  [limUiTooltip]="lang()?.SideBarHelp"
                  (click)="openHelp()"
                  icon="circleQuestionRegular"
                  iconSize="medium"
                  dataLogLabel="general-initiateHelpCenter"
               />

               <lim-ui-dropdown #dropdown [hoverDropdown]="true">
                  <div button #dropdownButton class="last-refreshed-container">
                     <lim-ui-minimal-icon-button
                        class="limble-header-nav-buttons dropdown-toggle"
                        (click)="handleRefreshClick()"
                        [disabled]="isRefreshing()"
                        id="navigationRefreshTimerDropdown"
                        icon="rotateRegular"
                        iconSize="medium"
                        [ngClass]="{ 'last-refreshed-timer--refreshing': isRefreshing() }"
                     />
                     <span class="lim-ui-show-on-desktop">
                        <div class="last-refreshed-text">
                           {{ lang()?.LastRefreshed }}:&nbsp;
                        </div>
                        @if (!isRefreshing()) {
                           <div class="last-refreshed-time">
                              {{ lastRefreshed() }}
                           </div>
                        } @else {
                           <div class="last-refreshed-time">
                              {{ lang()?.LastRefreshedTimeUpdating }}
                           </div>
                        }
                     </span>
                  </div>

                  <div menu>
                     <lim-ui-dropdown-text-item
                        [closeDropdownOnClick]="false"
                        [displayString]="lang()?.refreshNow ?? ''"
                        (click)="handleRefreshClick()"
                     />
                     <lim-ui-dropdown-text-item
                        [closeDropdownOnClick]="false"
                        [displayString]="lang()?.refreshEvery5Minutes ?? ''"
                        (click)="setRefreshInterval(5)"
                     />

                     <lim-ui-dropdown-text-item
                        [closeDropdownOnClick]="false"
                        [displayString]="lang()?.refreshEvery15Minutes ?? ''"
                        (click)="setRefreshInterval(15)"
                     />

                     <lim-ui-dropdown-text-item
                        [closeDropdownOnClick]="false"
                        [displayString]="lang()?.refreshEvery30Minutes ?? ''"
                        (click)="setRefreshInterval(30)"
                     />

                     <lim-ui-dropdown-text-item
                        [closeDropdownOnClick]="false"
                        [displayString]="lang()?.refreshEvery60Minutes ?? ''"
                        (click)="setRefreshInterval(60)"
                     />

                     <lim-ui-dropdown-divider />

                     <lim-ui-dropdown-text-item
                        [closeDropdownOnClick]="false"
                        [displayString]="refreshIntervalDisplay"
                     />

                     <lim-ui-dropdown-text-item
                        [closeDropdownOnClick]="false"
                        [displayString]="lang()?.disableAutoRefresh ?? ''"
                        (click)="setRefreshInterval(0)"
                     />
                  </div>
               </lim-ui-dropdown>

               @if (poweredBy || usingCustomLogo) {
                  <div class="powered-by">
                     <div>
                        <a href="https://limblecmms.com" target="_blank">
                           <span class="powered-by-text">
                              {{ lang()?.PoweredByLowercaseB }}
                           </span>
                        </a>
                     </div>
                     <div class="powered-by-logo">
                        <img
                           src="assets/img/logo-light-background.svg"
                           class="logoImgHome"
                           alt="Limble CMMS"
                        />
                     </div>
                  </div>
               }
            </div>
         </div>
      }
   </div>
</header>
