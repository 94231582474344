@if (lang() !== undefined) {
   <div class="main-sidebar">
      <div class="sidebar-top">
         @if (cusActive === true) {
            @if (creds[PermissionID.ViewGlobalDashboard] === true) {
               <div
                  (click)="toggleLocationMenu()"
                  [ngClass]="{
                     active: currentRouteName === 'dashboard' && !isLocationSidebarOpen,
                  }"
                  [routerLink]="['/dashboard']"
                  class="navItem"
                  data-test-id="GlobalDashboardNavButton"
                  limUiTooltip="{{ lang().globalDashboard }}"
                  placement="right"
                  data-log="globalNav-globalDashboardNavigation"
               >
                  <div class="iconWrapper">
                     <lim-ui-icon icon="earthAmericas" iconSize="medium" />
                  </div>
               </div>
            }

            @if (creds[PermissionID.ViewCustomDashboards] === true) {
               <div
                  (click)="toggleLocationMenu()"
                  [ngClass]="{
                     active:
                        currentRouteName === 'customDashboard' && !isLocationSidebarOpen,
                  }"
                  class="navItem"
                  data-test-id="CustomDashboardsNavButton"
                  limUiTooltip="{{ lang().CustomDashboards }}"
                  placement="right"
                  [routerLink]="['/customDashboard', currentDashboard]"
                  data-log="globalNav-customDashboardNavigation"
               >
                  <div class="iconWrapper">
                     <lim-ui-icon icon="gaugeHigh" iconSize="medium" />
                  </div>
               </div>
            }

            <div
               (click)="toggleLocationMenu()"
               class="navItem"
               [ngClass]="{ active: isLocationSidebarOpen }"
               limUiTooltip="{{ lang().Locations }}"
               data-test-id="LocationNavButton"
               placement="right"
               data-log="globalNav-locationsToggle"
            >
               <div
                  class="iconWrapper"
                  [ngClass]="{
                     openLocationMenu: isLocationSidebarOpen,
                  }"
               >
                  <lim-ui-icon icon="houseChimney" iconSize="medium" />
               </div>
            </div>

            @if (creds[PermissionID.ViewManageUsers] === true) {
               <div
                  (click)="toggleLocationMenu()"
                  [ngClass]="{
                     active:
                        currentRouteName === 'userManagement' && !isLocationSidebarOpen,
                  }"
                  [routerLink]="['/userManagement']"
                  class="navItem"
                  data-test-id="UserManagementNavButton"
                  limUiTooltip="{{ lang().Users }}"
                  placement="right"
                  data-log="globalNav-userManagementNavigation"
               >
                  <div class="iconWrapper">
                     <lim-ui-icon icon="user" iconSize="medium" />
                  </div>
               </div>
            }

            @if (creds[PermissionID.SetupAndConfigureWorkRequestPortal] === true) {
               <div
                  (click)="toggleLocationMenu()"
                  [ngClass]="{
                     active:
                        currentRouteName === 'setupWorkRequests' &&
                        !isLocationSidebarOpen,
                  }"
                  [routerLink]="['/setupWorkRequests']"
                  class="navItem"
                  data-test-id="WorkRequestNavButton"
                  limUiTooltip="{{ lang().setupWorkRequests }}"
                  placement="right"
                  data-log="globalNav-setupWorkRequestsNavigation"
               >
                  <div class="iconWrapper">
                     <lim-ui-icon icon="triangleExclamation" iconSize="medium" />
                  </div>
               </div>
            }

            @if (isAssetTemplatesEnabled && isSuperUser) {
               <div
                  (click)="toggleLocationMenu()"
                  [ngClass]="{
                     active:
                        currentRouteName === 'assetTemplates' && !isLocationSidebarOpen,
                  }"
                  [routerLink]="['/assetTemplates']"
                  class="navItem"
                  limUiTooltip="{{ lang().AssetTemplates }}"
                  placement="right"
                  data-log="assetTemplates-createAssetTemplate-clickGoToTemplatesPage"
               >
                  <div class="iconWrapper">
                     <lim-ui-icon icon="books" iconSize="medium" />
                  </div>
               </div>
            }

            @if (featureMaps !== 0 && creds[PermissionID.ViewMaps] === true) {
               <div
                  (click)="toggleLocationMenu()"
                  [ngClass]="{
                     active:
                        currentRouteName === 'mapManagement' && !isLocationSidebarOpen,
                  }"
                  [routerLink]="['/mapManagement', currentMap]"
                  class="navItem"
                  data-test-id="MapsNavButton"
                  limUiTooltip="{{ lang().Maps }}"
                  placement="right"
                  data-log="globalNav-mapManagementNavigation"
               >
                  <div class="iconWrapper">
                     <lim-ui-icon icon="map" iconSize="medium" />
                  </div>
               </div>
            }
            @if (
               featureFuse !== 0 &&
               creds[PermissionID.SetupAndConfigureWorkRequestPortal] === true
            ) {
               <div
                  [routerLink]=""
                  (click)="loginFuse()"
                  class="navItem"
                  data-test-id="FuseNavButton"
                  limUiTooltip="{{ lang().LaunchFuse }}"
                  placement="right"
                  data-log="globalNav-fuseNavigation"
               >
                  <div class="iconWrapper">
                     <lim-ui-icon icon="chartNetwork" iconSize="medium" />
                  </div>
               </div>
            }

            @if (creds[PermissionID.ManageRoles] === true) {
               <div
                  (click)="toggleLocationMenu()"
                  [ngClass]="{
                     active: currentRouteName === 'settings' && !isLocationSidebarOpen,
                  }"
                  [routerLink]="['/settings']"
                  class="navItem"
                  data-test-id="SettingsNavButton"
                  limUiTooltip="{{ lang().Settings }}"
                  placement="right"
                  data-log="globalNav-settingsNavigation"
               >
                  <div class="iconWrapper">
                     <lim-ui-icon icon="gear" iconSize="medium" />
                  </div>
               </div>
            }
         }
      </div>

      <div class="sidebar-bottom">
         @if (showWhatsNew) {
            <div class="navItem" limUiTooltip="{{ lang().WhatsNew }}" placement="right">
               <div class="iconWrapper">
                  <lim-ui-icon
                     icon="bullhorn"
                     data-test-id="WhatsNewNavButton"
                     iconSize="medium"
                     id="beamer-update"
                  />
               </div>
            </div>
         }

         <div class="navItem">
            <div class="iconWrapper notification-iconWrapper">
               <notifications-popover
                  [currentUser]="currentUser"
                  data-test-id="NotificationsNavButton"
                  position="right-end"
               />
            </div>
         </div>

         <ng-container>
            <div
               class="navItem"
               limUiTooltip="{{ currentUser?.userInfo?.fName }} {{
                  currentUser?.userInfo?.lName
               }}"
               [placement]="'right'"
            >
               <div
                  class="iconWrapper"
                  data-test-id="CurrentUserNavButton"
                  [limUiPopover]="accountManagementPopover"
                  [limUiPopoverPlacement]="'right-end'"
                  [limUiPopoverOffset]="16"
               >
                  @if (currentUser) {
                     <user-image [user]="currentUser" class="sidebar-user-image" />
                  }

                  <ng-template #accountManagementPopover>
                     <account-management-popover
                        [currentUser]="currentUser"
                        [showFeatureRequestBoard]="showFeatureRequestBoard"
                     />
                  </ng-template>
               </div>
            </div>
         </ng-container>
      </div>
   </div>
}

@if (isLocationSidebarOpen && lang() !== undefined) {
   <div class="locationSideBar">
      <div class="locationSidebarTopBlock">
         <div class="closeLocationMenu" (click)="toggleLocationMenu()">
            <lim-ui-icon icon="chevronLeft" />
         </div>
         <div class="locationsHeader">
            <span>{{ lang().Locations }}</span>
            @if (creds[PermissionID.ManageLocations] === true) {
               <lim-ui-text-button
                  [routerLink]="['/locationManagement']"
                  data-test-id="locationManageButton"
               >
                  {{ lang().SideBarManage }}
               </lim-ui-text-button>
            }
         </div>

         @if (locationsCount !== undefined && namedRegionsCount !== undefined) {
            @if (locationsCount > 5 || (namedRegionsCount > 0 && locationsCount > 2)) {
               <div class="searchContainer">
                  <lim-ui-search-box
                     [formControl]="searchControl"
                     [clearText]="lang().clear"
                     [placeholder]="lang().Search"
                  />
               </div>
            }
         }
      </div>

      @if (hierarchy !== undefined) {
         <div class="locationSidebarBottomBlock">
            @if (namedRegionsCount === 0) {
               @for (location of hierarchy.getUnassignedLocations(); track location) {
                  <div id="locNav" class="locationWrapper">
                     <nav-location
                        [locationID]="location.locationID"
                        [isChild]="false"
                        [defaultOpen]="hierarchy.getUnassignedLocations().length === 1"
                     />
                  </div>
               }
            } @else {
               @for (region of hierarchy.getTopLevel(); track region) {
                  <div class="regionWrapper">
                     <nav-region
                        [region]="region"
                        [hierarchy]="hierarchy"
                        [searchControl]="searchControl"
                     />
                  </div>
               }
            }

            @if (disabledHierarchy !== undefined) {
               @if (disabledNamedRegionsCount === 1) {
                  @for (
                     disabledLocation of disabledHierarchy.getUnassignedLocations();
                     track disabledLocation
                  ) {
                     <div id="locDisabledNav" class="locationWrapper">
                        <nav-location
                           [locationID]="disabledLocation.locationID"
                           [isChild]="false"
                        />
                     </div>
                  }
               } @else {
                  @for (
                     disabledRegion of disabledHierarchy.getTopLevel();
                     track disabledRegion
                  ) {
                     <div class="regionWrapper">
                        <nav-region
                           [region]="disabledRegion"
                           [hierarchy]="disabledHierarchy"
                           [regionIsDisabled]="true"
                        />
                     </div>
                  }
               }
            }

            @if (noSearchResults) {
               <div>
                  <img src="assets/img/notFoundRobot.png" />
                  <br />
                  <br />
                  <div>
                     {{ lang().WhoopsDrawingABlank }}
                  </div>
                  <div>
                     {{ lang().TrySearchingForTheLocationOrRegionsName }}
                  </div>
               </div>
            }
         </div>
      }
   </div>
}
