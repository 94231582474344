import { computed, inject } from "@angular/core";
import {
   Router,
   type ActivatedRouteSnapshot,
   type RouterStateSnapshot,
} from "@angular/router";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";

/**
 * Guard to redirect to another route if a user is not a super user
 * @param route - This is the ActivatedRouteSnapshot that is passed into the guard
 * @param state - This is the RouterStateSnapshot that is passed into the guard
 * @param redirectRoute - Route to redirect to if flag is not enabled
 */
export async function superUserGuard(
   route: ActivatedRouteSnapshot,
   state: RouterStateSnapshot,
   redirectRoute: string,
) {
   const alertService = inject(AlertService);
   const manageLang = inject(ManageLang);
   const manageLogin = inject(ManageLogin);
   const manageUser = inject(ManageUser);
   const manageUtil = inject(ManageUtil);
   const router = inject(Router);

   const lang = computed(() => manageLang.lang() ?? {});

   manageLogin.checkLogin();
   const currentUser = await manageUser.getCurrentUserFirstValue();
   const isSuperUser = manageUtil.checkIfSuperUser(currentUser);

   if (isSuperUser) {
      return true;
   }

   alertService.addAlert(
      lang().SuperUserOnlyAccessDeniedWarning,
      "warning",
      10000,
      lang().AccessDenied,
   );

   const { data } = route;
   const newUrl = state.url.replace(data.routeName, redirectRoute);
   return router.createUrlTree([newUrl]);
}
