import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { Component, computed, inject, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { LimUiModalRef } from "lim-ui";
import {
   CheckboxComponent,
   IconComponent,
   isMobile,
   LoadingAnimationComponent,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalHeaderComponent,
} from "lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { GlobalSearchResponse } from "src/app/shared/components/global/globalSearch/globalSearch.service";
import { GlobalSearchService } from "src/app/shared/components/global/globalSearch/globalSearch.service";
import { GlobalSearchAssetListComponent } from "src/app/shared/components/global/globalSearch/sections/assets/global-search-asset-list.component";
import { GlobalSearchPartListComponent } from "src/app/shared/components/global/globalSearch/sections/parts/global-search-part-list.component";
import { GlobalSearchPmListComponent } from "src/app/shared/components/global/globalSearch/sections/pms/global-search-pm-list.component";
import { GlobalSearchPoListComponent } from "src/app/shared/components/global/globalSearch/sections/pos/global-search-po-list.component";
import { GlobalSearchTaskListComponent } from "src/app/shared/components/global/globalSearch/sections/tasks/global-search-task-list.component";
import { GlobalSearchVendorListComponent } from "src/app/shared/components/global/globalSearch/sections/vendors/global-search-vendor-list.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { DataViewerStateService } from "src/app/shared/data-viewer/data-viewer-state.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import type {
   GlobalSearchEntities,
   GlobalSearchSettings,
} from "src/app/shared/types/general.types";
import { globalSearchEntitiesTuple } from "src/app/shared/types/general.types";

@Component({
   selector: "global-search",
   templateUrl: "./globalSearch.component.html",
   styleUrls: ["./globalSearch.component.scss"],
   providers: [GlobalSearchService, DataViewerStateService],
   standalone: true,
   imports: [
      ModalComponent,
      ModalDirective,
      ModalHeaderComponent,
      MinimalIconButtonComponent,
      NgClass,
      IconComponent,
      FormsModule,
      CheckboxComponent,
      ModalBodyComponent,
      LoadingAnimationComponent,
      NoSearchResults,
      GlobalSearchTaskListComponent,
      GlobalSearchPmListComponent,
      GlobalSearchAssetListComponent,
      GlobalSearchPartListComponent,
      GlobalSearchVendorListComponent,
      GlobalSearchPoListComponent,
   ],
})
export class GlobalSearchComponent implements OnInit {
   protected dataInitializing: boolean;
   protected searchStringInput: string;
   protected search: string | null = null;
   private modalInstance: LimUiModalRef | null = null;
   private readonly searchCharacterCountRestriction: number = 1;
   protected settings: GlobalSearchSettings;
   protected settingsLang: Map<GlobalSearchEntities, string>;
   protected searchEntities = globalSearchEntitiesTuple;

   private readonly taskSearchRequestState = signal<
      | {
           isLoadingResults: boolean;
           isEmpty: boolean;
           hasError: boolean;
        }
      | undefined
   >(undefined);

   protected readonly isMobile: boolean;
   private readonly defaultEntityOptionSettings: GlobalSearchSettings = {
      tasks: true,
      pms: true,
      vendors: true,
      assets: true,
      parts: true,
      pos: true,
   };
   protected responseIDs:
      | {
           tasks: Array<number>;
           pms: Array<number>;
           pos: Array<number>;
           assets: Array<number>;
           parts: Array<number>;
           vendors: Array<number>;
        }
      | undefined = undefined;

   // TODO: As part of CMMS-3449 remove this signal
   public showPMsList = signal(false);

   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly globalSearchService = inject(GlobalSearchService);
   private readonly refreshService = inject(RefreshService);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly isLoadingLegacyGlobalSearchResults = signal<boolean>(false);
   public isLoading = computed(() => {
      return (
         this.isLoadingLegacyGlobalSearchResults() ||
         this.taskSearchRequestState()?.isLoadingResults
      );
   });
   private readonly isLegacyGlobalSearchResultsEmpty = signal<boolean>(false);
   public isEmpty = computed(() => {
      if (this.taskSearchRequestState() === undefined) {
         return this.isLegacyGlobalSearchResultsEmpty();
      }
      return (
         this.isLegacyGlobalSearchResultsEmpty() && this.taskSearchRequestState()?.isEmpty
      );
   });

   public constructor() {
      this.isMobile = isMobile();
      this.searchStringInput = "";
      this.dataInitializing = true;
      this.refreshService.dataInitialized().subscribe(() => {
         this.dataInitializing = false;
      });

      // Set the language objects to the db column names.
      this.settingsLang = new Map([
         ["tasks", this.lang().Tasks],
         ["pms", this.lang().PMs],
         ["assets", this.lang().Assets],
         ["parts", this.lang().Parts],
         ["vendors", this.lang().Vendors],
         ["pos", this.lang().POs],
      ]);
      this.settings = this.defaultEntityOptionSettings;

      // Set the global search user preferences
      this.initializeUserPreferences();
   }

   public ngOnInit(): void {
      const params = this.paramsService.params;
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
   }

   protected clearSearch(): void {
      this.searchStringInput = "";
      this.responseIDs = undefined;
   }

   public close(): void {
      this.modalInstance?.close();
   }

   public canSearch(globalSearchFilter: string | null): boolean {
      return (
         !this.isLoading() &&
         globalSearchFilter !== null &&
         globalSearchFilter.trim().length >= this.searchCharacterCountRestriction
      );
   }

   private async initializeUserPreferences(): Promise<void> {
      const globalSearchSettings =
         await this.globalSearchService.getGlobalSearchSettings();
      if (globalSearchSettings != null) {
         for (const setting in globalSearchSettings) {
            this.settings[setting] = Boolean(globalSearchSettings[setting]);
         }
      }
   }

   private resultsAreEmpty(ids: GlobalSearchResponse | undefined): boolean {
      const legacyResponsesEmpty = Boolean(
         ids &&
            ids.tasks.length === 0 &&
            ids.assets.length === 0 &&
            ids.pms.length === 0 &&
            ids.pos.length === 0 &&
            ids.parts.length === 0 &&
            ids.vendors.length === 0,
      );

      return legacyResponsesEmpty;
   }

   protected async submitSearch(): Promise<void> {
      const search = this.searchStringInput?.replace(/^#/, "") ?? "";
      if (!this.canSearch(search)) return;
      this.taskSearchRequestState.set(undefined);
      this.responseIDs = undefined;
      this.search = search;
      this.isLoadingLegacyGlobalSearchResults.set(true);
      this.isLegacyGlobalSearchResultsEmpty.set(false);
      const response = await this.sendSearchRequest(search, this.settings);

      this.isLoadingLegacyGlobalSearchResults.set(false);
      if (this.resultsAreEmpty(response)) {
         this.isLegacyGlobalSearchResultsEmpty.set(true);
         return;
      }
      this.responseIDs = response;

      // TODO: As part of CMMS-3449 remove this signal
      this.showPMsList.set(Boolean(this.responseIDs?.pms?.length));
   }

   private async sendSearchRequest(
      search: string,
      options: GlobalSearchSettings,
   ): Promise<GlobalSearchResponse | undefined> {
      const searchOptions: GlobalSearchSettings = {
         ...options,
         tasks: false,
      };

      return this.globalSearchService
         .getGlobalSearchResults(search, searchOptions)
         .then((answer) => answer.data)
         .catch(() => {
            this.alertService.addAlert(this.lang().GlobalSearchErrMsg, "danger", 6000);
            this.isLoadingLegacyGlobalSearchResults.set(false);
            return undefined;
         });
   }

   public handleTaskSearchRequestStateChange(requestState: {
      isLoadingResults: boolean;
      isEmpty: boolean;
      hasError: boolean;
   }) {
      this.taskSearchRequestState.set(requestState);
   }

   public checkboxChangeHandler(entity: GlobalSearchEntities): void {
      this.settings[entity] = !this.settings[entity];
      this.globalSearchService.updateGlobalSearchSettings(this.settings);
   }

   protected checkAll(): void {
      for (const entity in this.settings) {
         this.settings[entity] = true;
      }
      this.globalSearchService.updateGlobalSearchSettings(this.settings);
   }

   protected uncheckAll(): void {
      for (const entity in this.settings) {
         this.settings[entity] = false;
      }
      this.globalSearchService.updateGlobalSearchSettings(this.settings);
   }

   protected getEntityLabel(entity: GlobalSearchEntities): string {
      const entityLabel = this.settingsLang.get(entity);
      if (!entityLabel) {
         return "";
      }
      return entityLabel;
   }

   protected getEntityChecked(entity: GlobalSearchEntities): boolean {
      return this.settings[entity];
   }
}
