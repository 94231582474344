import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   OutlinedButtonComponent,
   PrimaryButtonComponent,
   IconComponent,
   TextButtonComponent,
   LimUiModalRef,
} from "lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { ConfirmModalComponent } from "src/app/lite/ui/confirm-modal/confirm-modal.component";
import { RoiPanelComponent } from "src/app/shared/components/global/insights-modal/roi-panel/roi-panel.component";

@Component({
   selector: "insights",
   templateUrl: "./insights-modal.component.html",
   standalone: true,
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      OutlinedButtonComponent,
      PrimaryButtonComponent,
      IconComponent,
      TextButtonComponent,
      RoiPanelComponent,
   ],
})
export class InsightsModalComponent {
   private readonly manageLang = inject(ManageLang);
   public readonly modalRef: LimUiModalRef<ConfirmModalComponent, boolean> =
      inject(LimUiModalRef);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   close = () => {
      this.modalRef.close(false);
   };
}
