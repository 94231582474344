@if (parts.size > 0) {
   <div class="global-search-section">
      <lim-ui-panel [title]="lang().Parts" [noSidePadding]="true" [table]="true">
         <div class="listTable">
            <div class="listItem listItemHeader">
               <div class="col-md-4 mobileMediumDisappear">
                  <sort-column_refactor [options]="sortColumnInfo.name" />
               </div>
               <div class="col-md-8 mobileMediumDisappear column-header-after-badges">
                  <sort-column_refactor [options]="sortColumnInfo.location" />
               </div>
            </div>
            @for (part of partsInView; track part) {
               <div
                  class="listItem"
                  [ngClass]="{
                     tranactionEveryOther: $odd == false,
                     tranactionEveryOtherWhite: $odd == true,
                  }"
                  (click)="openPart(part)"
               >
                  <div class="col-md-4 table-column-badge-and-name-column">
                     <div class="icon-col">
                        <lim-ui-icon icon="gears" iconSize="small" />
                        <a class="cursor table-link" [limbleHtml]="part.partName"></a>
                     </div>

                     @if (searchHints.get(part.partID); as searchHint) {
                        <div class="table-column-badge" [limbleHtml]="searchHint"></div>
                     }
                  </div>

                  <div class="col-md-8">
                     <span>
                        {{ locationsIndex[part.locationID].locationName }}
                     </span>
                  </div>
               </div>
            }
         </div>
      </lim-ui-panel>
      <div class="text-center">
         @if (parts.size > itemsPerPage) {
            <lim-ui-pagination
               [maxSize]="5"
               [rotate]="true"
               [page]="page"
               [pageSize]="itemsPerPage"
               [collectionSize]="parts.size"
               (pageChange)="pageChange($event)"
            />
         }
      </div>
   </div>
}
