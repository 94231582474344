<div
   class="popover-parent"
   limUiTooltip="{{ lang()?.SideBarNotifications }}"
   [placement]="'right'"
   [limUiPopover]="popover"
   [limUiPopoverPlacement]="position"
   [limUiPopoverOffset]="16"
   [limUiPopoverCloseCallback]="onNotificationsPopoverClose"
>
   <lim-ui-icon icon="bell" iconSize="medium" />
   @if (notificationsCount() > 0) {
      <div
         class="notification-badge"
         [ngClass]="{
            bubble: notificationsCount() < 10,
            pill: notificationsCount() >= 10,
            large: notificationsCount() > 99,
         }"
      >
         {{ notificationsCount() > 99 ? "99+" : notificationsCount() }}
      </div>
   }

   <ng-template #popover>
      <div class="notifications-popover">
         <div class="notifications-header">
            <div class="mobile-title-wrapper">
               <div class="mobile-title-subcontainer">
                  <lim-ui-icon icon="bell" [iconSize]="'medium'" />
                  <div class="title-text">{{ lang()?.Notifications }}</div>
               </div>
               <lim-ui-icon
                  (click)="close()"
                  class="mobile-title-close"
                  icon="xmarkLargeRegular"
                  [iconSize]="'medium-large'"
               />
            </div>

            <div class="notifications-title lim-ui-hide-on-mobile">
               <lim-ui-icon icon="bell" iconSize="medium" />
               <b>{{ lang()?.SideBarNotifications }}</b>
            </div>

            @if (!loading) {
               <lim-ui-search-box
                  [fullWidth]="true"
                  [placeholder]="lang()?.Search ?? ''"
                  [(searchVal)]="searchBarNotifications"
                  (searchValChange)="updateNotificationsSearch()"
               />
               <div class="notifications-buttons">
                  <span
                     class="red text"
                     data-log="notifications-initiateDeleteAll"
                     (click)="deleteAllNotifications(); $event.stopPropagation(); close()"
                  >
                     {{ lang()?.DeleteAll }}
                  </span>

                  <span
                     class="blue text"
                     data-log="notifications-markAllAsRead"
                     (click)="
                        markAllNotificationsAsRead(); $event.stopPropagation(); close()
                     "
                  >
                     {{ lang()?.MarkAllAsRead }}
                  </span>
                  <span
                     class="blue text"
                     [attr.data-log]="
                        notificationsView == 1 ? undefined : 'notifications-showUnread'
                     "
                     (click)="toggleNotificationsView(); $event.stopPropagation()"
                  >
                     {{ notificationsView == 1 ? lang()?.ShowAll : lang()?.ShowUnread }}
                  </span>
               </div>
            }
         </div>
         <div class="body">
            @if (loading) {
               <div class="loading-icon notifications-loading">
                  <lim-ui-loading-animation [animationSize]="'small'" />
               </div>
            }
            @if (!loading) {
               <lim-ui-scroll-container [thin]="true" (refreshEvent)="refreshPage()">
                  @if (notifications && notifications.length > 0) {
                     <ul id="notificationHolder" class="notifications-list">
                        @for (
                           notification of notifications;
                           track notification;
                           let isLast = $last
                        ) {
                           <li
                              data-log="notifications-viewNotification"
                              (click)="viewNotification(notification)"
                              [ngClass]="{ last: isLast }"
                           >
                              <span class="unread-badge-container">
                                 @if (notification.visited === 0) {
                                    <span
                                       [title]="lang()?.ThisNotificationHasntBeenVisted"
                                       class="unread-badge"
                                    >
                                    </span>
                                 }
                              </span>
                              <lim-ui-icon
                                 [icon]="notification | notificationIcon: 'primary'"
                                 iconSize="medium"
                                 [title]="notification.type | notificationTitle"
                                 [iconColor]="
                                    notification.type == 16 ? 'blue' : 'inherit'
                                 "
                              />

                              <div>
                                 <p class="notification-message">
                                    @if (
                                       notification.checklistID > 0 ||
                                       notification.poID > 0 ||
                                       notification.prID > 0 ||
                                       notification.assetID > 0
                                    ) {
                                       <span class="secondary-icon">
                                          <lim-ui-icon
                                             [icon]="
                                                notification
                                                   | notificationIcon: 'secondary'
                                             "
                                             iconSize="small"
                                          />
                                       </span>
                                    }
                                    <span [innerHTML]="notification.message"></span>
                                    @if (notification.type === 16) {
                                       <span class="upsell-notification">
                                          <span class="table-link">
                                             {{ lang().LearnMore }}</span
                                          >
                                          <lim-ui-icon
                                             class="icon-link"
                                             [icon]="'arrowUpRightFromSquare'"
                                             iconSize="small"
                                          />
                                       </span>
                                    }
                                    @if (
                                       notification.checklistID > 0 &&
                                       notification.type == 1
                                    ) {
                                       <span>
                                          - {{ notification.checklistName }} - #{{
                                             notification.checklistID
                                          }}
                                       </span>
                                    }
                                 </p>

                                 <div class="notification-date">
                                    {{
                                       notification.timestamp * 1000
                                          | betterDate: "dateTimeWithSeconds"
                                    }}
                                 </div>
                              </div>
                           </li>
                        }
                     </ul>
                  } @else {
                     <div class="nostate">
                        <h3>{{ lang()?.WhoopsDrawingABlank }}</h3>
                        <h5>{{ lang()?.TryAdjustingYourSearchOrFilters }}</h5>
                     </div>
                  }
               </lim-ui-scroll-container>
            }
         </div>
      </div>
   </ng-template>
</div>
